import React, { useState } from "react";
import { Button, Col, Drawer, Image, Layout, Row } from "antd";
import imageLogo from "../images/header/isologo_original_1.png";
import imageLogoFooter from "../images/footer/Logo blanco.svg";
import imageMailFooter from "../images/footer/fluent_mail-16-filled.svg";
import imageLinkedInFooter from "../images/footer/akar-icons_linkedin-box-fill.svg";
import imageUbiFooter from "../images/footer/ic_baseline-place.svg";
import { HashLink } from "react-router-hash-link";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import AOS from "aos";
import ReactFlagsSelect from "react-flags-select";
import es_json from "../locale/es";
import eng_json from "../locale/eng";
import br_json from "../locale/br";
import { Outlet } from "react-router-dom";
import { useLocalStorage } from "../utils/utils";

AOS.init();
const { Header, Footer, Content } = Layout;

export default function DefaultLayout() {
  const { width } = useWindowDimensions();
  const [path, setPath] = useState("/");
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isMobile = () => {
    return width < 992;
  };

  const [select, setSelect] = useLocalStorage("lang", "ES");

  let var_json_lan = es_json["es_json"];

  if (select == "US") {
    var_json_lan = eng_json["eng_json"];
  } else if (select == "ES") {
    var_json_lan = es_json["es_json"];
  } else if (select == "BR") {
    var_json_lan = br_json["br_json"];
  }
  const currentYear = new Date().getFullYear();

  return (
    <Layout>
      <Header className="bg-bg_liricus fixed w-screen h-24 z-50">
        <div className="flex flex-1 justify-between items-stretch h-full md:w-full max-w-10xl mx-auto">
          <div className="flex flex-1 items-center mt-4">
            <a href="/" className="items-center">
              <Image src={imageLogo} preview={false} />
            </a>
          </div>
          <div className="flex flex-1 justify-end items-center">
            {!isMobile() && (
              <>
                <HashLink
                  smooth
                  to={path == "/" ? "#what-do-we-offer" : "/"}
                  className="hover-underline-animation text-xl h-fit mx-6 "
                >
                  {var_json_lan["Servicios"]}
                </HashLink>
                <HashLink
                  smooth
                  to={"/portfolio"}
                  className="hover-underline-animation text-xl h-fit mx-6"
                >
                  {var_json_lan["Portafolio"]}
                </HashLink>
                <HashLink
                  smooth
                  to={path == "/" ? "#contact-us" : "/"}
                  className="bg-white  border-2 border-amber-500 hover:border-primary text-amber-500 hover:text-primary text-xl px-12 h-12 rounded-full inline-flex items-center ml-6"
                >
                  {var_json_lan["Contactanos"]}
                </HashLink>
                <ReactFlagsSelect
                  countries={["US", "ES", "BR"]}
                  placeholder="Lenguaje"
                  selected={select}
                  onSelect={setSelect}
                  optionsSize={25}
                  showSelectedLabel={false}
                  showOptionLabel={false}
                  id="flags"
                  selectedSize={30}
                />
              </>
            )}
            {isMobile() && (
              <Button
                icon={<MenuOutlined className="text-slate-400" />}
                onClick={showDrawer}
                id="button-draw"
              ></Button>
            )}
          </div>
          <Drawer
            title={false}
            placement="right"
            onClose={onClose}
            open={open}
            headerStyle={{
              backgroundColor: "#F9F9F9",
              color: "#E8E8E8",
            }}
            bodyStyle={{
              backgroundColor: "#F9F9F9",
              color: "#E8E8E8",
            }}
            closeIcon={<CloseOutlined className="text-primary" />}
          >
            <HashLink
              smooth
              to={"#what-do-we-offer"}
              className="text-primary hover:text-custom_yellow/80 text-xl h-fit mb-6 block"
              onClick={onClose}
            >
              {var_json_lan["Servicios"]}
            </HashLink>
            <HashLink
              smooth
              to={"/portfolio"}
              className="text-primary hover:text-custom_yellow/80 text-xl h-fit mb-6 block"
              onClick={onClose}
            >
              {var_json_lan["Portafolio"]}
            </HashLink>
            <HashLink
              smooth
              to={"#contact-us"}
              className="bg-primary hover:bg-white/80 text-white hover:text-white text-xl px-12 h-12 rounded-md inline-flex items-center mb-6 block"
              onClick={onClose}
            >
              {var_json_lan["Contactanos"]}
            </HashLink>
            <ReactFlagsSelect
              countries={["US", "ES", "BR"]}
              placeholder="Lenguaje"
              selected={select}
              onSelect={setSelect}
              optionsSize={30}
              showSelectedLabel={false}
              showOptionLabel={false}
              id="flags-mobile"
              selectedSize={30}
            />
          </Drawer>
        </div>
      </Header>

      <Content className="mt-24 bg-white w-screen">
        <div className="w-full mx-auto">
          <Outlet context={{ json_lan: var_json_lan, setPath }} />
        </div>
      </Content>

      <Footer className="bg-primary w-full p-5">
        {!isMobile() && (
          <Row
            className="flex flex-1 items-center h-full w-full"
            justify="space-evently"
          >
            {/* PRIMER COLUMNA, CON EL LOGO DE LIRICUS */}

            <Col className="flex flex-1">
              <Image
                src={imageLogoFooter}
                className="md:w-58 md:m:20"
                preview={false}
              />
            </Col>

            {/* SEGUNDA COLUMNA, CON EL TEL, MAIL Y LINKEDIN */}

            <Col
              className="flex flex-1 md:inline"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="600"
            >
              <Row className="flex flex-1 items-center h-full w-full">
                <Col className="flex flex-r">
                  <Image
                    src={imageMailFooter}
                    className="w-10 m:7 mr-4 mb-2 "
                    preview={false}
                  />
                </Col>
                <Col className="flex flex-1">
                  <p className="md:inline mb-2 text-white text-bold text-sm md:text-xl font-bold">
                    hola@liricus.com.ar
                  </p>
                </Col>
              </Row>
              <Row className="flex flex-1 items-center h-full w-full">
                <Col className="flex flex-r">
                  <Image
                    src={imageLinkedInFooter}
                    className="w-10 m:7 mr-4 mb-2"
                    preview={false}
                  />
                </Col>
                <Col className="flex flex-1">
                  <p className="md:inline mb-2 text-white text-bold text-sm md:text-xl font-bold">
                    <a
                      className="hover:text-custom_orange"
                      href="https://ar.linkedin.com/company/liricus"
                      target="_blank"
                    >
                      Linkedin
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>

            {/* TERCERA COLUMNA, CON LAS UBICACIONES */}

            <Col className="flex flex-1 md:inline">
              <Row className="flex flex-1 items-center h-full w-full">
                <Col className="flex flex-r">
                  <Image
                    src={imageUbiFooter}
                    className="w-10 m:7 mr-4 mb-2"
                    preview={false}
                  />
                </Col>
                <Col className="flex flex-1">
                  <p className="md:inline mb-2 text-white text-bold text-sm md:text-xl font-bold">
                    Córdoba - Argentina
                  </p>
                </Col>
              </Row>
              <Row className="flex flex-1 items-center h-full w-full">
                <Col className="flex flex-r">
                  <Image
                    src={imageUbiFooter}
                    className="w-10 m:7 mr-4 mb-2"
                    preview={false}
                  />
                </Col>
                <Col className="flex flex-1 ">
                  <p className="md:inline mb-2 text-white text-bold text-sm md:text-xl font-bold ">
                    Aventura, Florida
                  </p>
                </Col>
              </Row>
            </Col>

            {/* CUARTA COLUMNA, CON SERVICIOS, NOSOTROS Y PORTFOLIO */}

            <Col className="flex flex-1 flex-col items-center">
              <HashLink
                smooth
                className="mb-2 hover:text-custom_orange text-white text-bold text-sm md:text-xl font-bold"
                to={"#what-do-we-offer"}
              >
                {var_json_lan["Servicios"]}
              </HashLink>
              <HashLink
                className="mb-2 hover:text-custom_orange text-white text-bold text-sm md:text-xl font-bold"
                to={"/portfolio"}
              >
                {var_json_lan["Portafolio"]}
              </HashLink>
            </Col>
          </Row>
        )}

        {isMobile() && (
          <Row
            className="flex flex-1 items-center h-full w-full"
            justify="space-evently"
          >
            <Col>
              <Image src={imageLogoFooter} className="w-20" preview={false} />
              <Row className="flex flex-1 items-center h-full w-full">
                <Col className="flex flex-r">
                  <Image
                    src={imageMailFooter}
                    style={{ width: 35 }}
                    preview={false}
                  />
                </Col>
                <Col className="flex flex-1">
                  <p className="text-white text-bold text-sm font-bold ">
                    hola@liricus.com.ar
                  </p>
                </Col>
              </Row>
              <Row className="flex flex-1 items-center h-full w-full ">
                <Col className="flex flex-r">
                  <Image
                    src={imageLinkedInFooter}
                    className=""
                    preview={false}
                  />
                </Col>
                <Col className="flex flex-1">
                  <p className="text-white text-bold text-sm font-bold ml-2">
                    <a
                      href="https://ar.linkedin.com/company/liricus"
                      target="_blank"
                    >
                      Linkedin
                    </a>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col className="flex flex-1 flex-col items-center mt-10">
              <HashLink
                smooth
                className="mb-2 text-white hover:text-custom_orange text-bold text-sm md:text-xl font-bold"
                to={"#what-do-we-offer"}
              >
                {var_json_lan["Servicios"]}
              </HashLink>
              <HashLink
                className="mb-2 text-white hover:text-custom_orange text-bold text-sm md:text-xl font-bold"
                to={"/portfolio"}
              >
                {var_json_lan["Portafolio"]}
              </HashLink>
            </Col>
          </Row>
        )}
      </Footer>

      <div
        className="w-full h-10 bg-background_footer pb-6 pt-2.5"
        id="div-copy"
      >
        <p
          className="text-white text-bold text-base font-bold text-center "
          id="copyright"
        >
          {var_json_lan["© Copyright"] +
            " " +
            currentYear +
            " " +
            var_json_lan[
              "Servicios tecnológicos - Todos los derechos reservados."
            ]}
        </p>
      </div>
    </Layout>
  );
}
