const br_json = {
    "Servicios":"Serviços",
    "Portafolio":"Portifólio",
    "Contactanos":"Contacte-nos",
    "Desarrollo de softwares":"Programação de softwares",
    "Construimos soluciones de software y aplicaciones con infraestructura escalables":"Construimos soluções de software e aplicativos com infraestrutra escaláveis",
    "Conocenos":"Saiba mais",
    "¿Quiénes somos?":"Quem somos?",
    "Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de alto rendimiento. Somos expertos en transformar sus necesidades en soluciones.":"Liricus é formada por uma equipe de programadores experientes, de alto nível e sincronizados de alto desempenho. Somos especialistas em transformar suas necessidades em soluções.",
    "Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de" : "Liricus é formada por uma equipe de programadores experientes, de alto nível e sincronizados de ",
    "alto rendimiento": " alto desempenho. ",
    "Somos expertos en transformar sus necesidades en soluciones.": "Somos especialistas em transformar suas necessidades em soluções.",
    "Nuestro proceso":"Nosso Processo",
    "El desencadenate":"A ação",
    "Tienes una necesidad":"Seus requerimentos",
    "El contacto":"O contato",
    "¡Dinos qué necesitas!":"Conte-nos seus requerimentos.",
    "Evaluación":"Avaliação",
    "Analizaremos tu solicitud.":"Analisaremos seu pedido",
    "La respuesta":"A resposta",
    "Tu solicitud fue aprobada.":"Seu pedido foi aprovado",
    "La solución":"A solução",
    "Pongámonos manos a la obra.":"Mãos a obra!",
    "¿Qué ofrecemos?":"Que oferecemos?",
    "Plataforma en la nube AWS y Python":"AWS Cloud e Python",
    "Plataformas AWS en framework Python, Django, Flask, etc.":"Framework bem arquitetado, Python, Django, Flask, etc.",
    "Desarrollo de aplicaciones":"Desenvolvimento de aplicativos",
    "Objective-C, Swift, Android, Kotlin, React native & flutter":"Objective-C, Swift, Android, Kotlin, React native & flutter",
    "Equipo disponible según necesidad":"Equipe sob demanda",
    "Configuración del equipo personalizable, desarrolladores, líderes tecnológicos, administración.":"Configuração de equipe personalizável, programadores, líderes técnicos, gerenciamento.",
    "Nuestra fuerza":"Nossa força",
    "Rentabilidad/Rendimiento":"Custo benefício",
    "Infraestructura flexible on-demand basada en el marco AWS well-architected framework":"Infraestrutura elástica sob medida baseada na estrutura arquitetada da AWS.",
    "Las personas en primer lugar":"Pessoas em primeiro lugar",
    "Nuestra cultura de trabajo es nuestro principal recurso, como un equipo boutique que trabaja en estrecha colaboración con nuestros parceros.":"A nossa cultura de trabalho é o nosso principal trunfo, como uma equipe boutique que trabalha em estreita colaboração com nossos parceiros.",
    "Tasa competitiva":"Tarifa competitiva",
    "Servicios profesionales altamente calificados con horas/tarifas de economías emergentes.":"Serviços profissionais altamente qualificados com horas/taxas de economias emergentes.",
    "Innovador":"Equipe de ponta",
    "Equipo altamente calificado, líderes y arquitectos tecnológicos con más de 10 años de experiencia.":"Equipe altamente qualificada, líderes de tecnologia e arquitetos com mais de 10 anos de experiência.",
    "Maximiliano Abrutsky":"Maximiliano Abrutsky",
    "Nuestro gerente general tiene más de 15 años de experiencia en el campo, tiene un posgrado en ingeniería de sistemas y tiene experiencia como profesor universitario. Es un emprendedor tecnológico dedicado y uno de los primeros inversionistas en startups de deportes y salud.":"Nosso gerente geral tem mais de 15 anos de experiência trabalhando na área, é pós-graduado em engenharia de sistemas e possui experiência como professor universitário. Ele é um empreendedor de tecnologia dedicado e um dos primeiros investidores em startups de esportes e saúde.",
    "Manuel Luque":"Manuel Luque",
    "Nuestro vicepresidente de ingeniería es el jefe de operaciones de la empresa. Durante los últimos 30 años, ha dirigido equipos de programadores de primer nivel. Tiene experiencia en ingeniería de sistemas y una sólida formación técnica como DBA, arquitecto de la nube de AWS, backend y programador móvil.":"Nosso vice-presidente de engenharia é o chefe de operações da empresa. Nos últimos 30 anos, ele liderou equipes de programadores de alto nível. Possui formação em engenharia de sistemas e sólida formação técnica como DBA, arquiteto de nuvem AWS, back-end e programador de dispositivos móveis.",
    "Nuestras tecnologías":"Nossas tecnologias",
    "Nuestros números":"Nossos números",
    "10 Tecnologías esenciales":"10 Principais tecnologias",
    "Expertos en los lenguages más utilizados.":"Especialistas nas linguagens mais adotadas.",
    "+13 Años de experiencia":"+13 Anos de experiência",
    "Equipo multidisciplinario y multicultural.":"Equipe multidisciplinar e multicultural.",
    "3 Industrias principales":"3 Principais indústrias",
    "Sportechs, Healthtechs & Insurtechs.":"Sportechs, Healthtechs & Insurtechs.",
    "300 Proyectos":"+300 Projetos",
    "Garantizamos eficiencia y calidad en nuestras entregas.":"Garantimos eficiência e qualidade em nossas entregas.",
    "¡Tomemos un café!":"Tomamos um café?",
    "No debería tomarnos tanto tiempo programar una reunión de 15 minutos":"Não deveria demorar tantos e-mails para agendar uma reunião de 15 minutos",
    "No debería tomarnos tanto tiempo": "Não deveria demorar tantos e-mails",
    "programar una reunión de 15 minutos": "para agendar uma reunião de 15 minutos",
    "Nombre":"Nome",
    "Dirección de e-mail":"Endereço de e-mail",
    "Asunto":"Assunto",
    "Mensaje":"Mensagem",
    "Enviar":"Enviar",
    "Tú Nombre": "Seu nome",
    "Tú Email": "Seu email",
    "Interesado en": "Interesado en",
    "Requerido": "Obrigatório",
    "© Copyright 2022 Servicios tecnológicos - Todos los derechos reservados.":"Direitos Autorais @ 2022 Liricus. Serviços tecnológicos - Todos os direitos reservados. ",
    "¡Pagina web en construccion!": "Site em Construção",
}

module.exports = {
    br_json
}