import { Col, Image, Row, Typography } from "antd";
import build_img from "../images/build/build_2x.svg";
import { useOutletContext } from "react-router-dom";

const { Title, Paragraph } = Typography;
export default function Building(props) {
  const { json_lan, setPath } = useOutletContext();
  setPath(window.location.pathname);

  return (
    <div id="portfolio" className=" items-center">
      <Row justify="space-around items-center w-full h-screen">
        <Col>
          <Title className="text-center text-primary mt-56" id="title-building">
            {json_lan["¡Pagina web en construccion!"]}
          </Title>
        </Col>
        <Col>
          <Image src={build_img} preview={false}></Image>
        </Col>
      </Row>
    </div>
  );
}
