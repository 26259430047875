const eng_json = {
    "undefined":"8va FAJA",
   "Servicios":"Services",
   "Portafolio":"Portfolio",
   "Contactanos":"Contact us",
   "Desarrollo de softwares":"Software development",
   "Construimos soluciones de software y aplicaciones con infraestructura escalables":"We build software solutions and applications with scalable infrastructure",
   "Conocenos":"Get to know us",
   "¿Quiénes somos?":"Who are we?",
   "Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de alto rendimiento. Somos expertos en transformar sus necesidades en soluciones.":"Liricus is an experienced, high-seniority, synchronized high-performance development team. We are experts in turning your needs into solutions.",
   "Liricus es un equipo de desarrollo experimentado, de gran antigüedad, sincronizado y de" : "Liricus is an experienced, high-seniority, synchronized ",
    "alto rendimiento": "high-performance",
    "Somos expertos en transformar sus necesidades en soluciones.": "development team. We are experts in turning your needs into solutions.",
   "Nuestro proceso":"Our process",
   "El desencadenate":"The trigger",
   "Tienes una necesidad":"You have a need.",
   "El contacto":"The contact",
   "¡Dinos qué necesitas!":"You will tell us your requirements.",
   "Evaluación":"Evaluation",
   "Analizaremos tu solicitud.":"We will analyze your request.",
   "La respuesta":"The response",
   "Tu solicitud fue aprobada.":"Your request was approved.",
   "La solución":"The solution",
   "Pongámonos manos a la obra.":"Let ́s start working.",
   "¿Qué ofrecemos?":"What do we offer?",
   "Plataforma en la nube AWS y Python":"AWS Cloud and python",
   "Plataformas AWS en framework Python, Django, Flask, etc.":"Well architected framework, Python, Django, Flask, etc.",
   "Desarrollo de aplicaciones":"App development",
   "Objective-C, Swift, Android, Kotlin, React native & flutter":"Objective-C, Swift, Android, Kotlin, React native & flutter",
   "Equipo disponible según necesidad":"On-demand team",
   "Configuración del equipo personalizable, desarrolladores, líderes tecnológicos, administración.":"Customizable team configuration, devs, tech leads, management,",
   "Nuestra fuerza":"Our strength",
   "Rentabilidad/Rendimiento":"Cost efficiency",
   "Infraestructura flexible on-demand basada en el marco AWS well-architected framework":"Elastic on-demand infrastructure based on AWS well-architected framework.",
   "Las personas en primer lugar":"People first",
   "Nuestra cultura de trabajo es nuestro principal recurso, como un equipo boutique que trabaja en estrecha colaboración con nuestros parceros.":"Our work culture is our main asset, as a boutique team that works closely with our partner.",
   "Tasa competitiva":"Competitive rate",
   "Servicios profesionales altamente calificados con horas/tarifas de economías emergentes.":"High-skilled professional services with emerging economies hour/rates.",
   "Innovador":"Cutting-edge",
   "Equipo altamente calificado, líderes y arquitectos tecnológicos con más de 10 años de experiencia.":"High-skilled team, tech leads and architects with more than 10 years of experience.",
   "Maximiliano Abrutsky":"Maximiliano Abrutsky",
   "Nuestro gerente general tiene más de 15 años de experiencia en el campo, tiene un posgrado en ingeniería de sistemas y tiene experiencia como profesor universitario. Es un emprendedor tecnológico dedicado y uno de los primeros inversionistas en startups de deportes y salud.":"Our general manager has more than 15 years of experience working in the field, has a postgraduate degree in system engineering, and has a university professor background. He is a devoted tech entrepreneur and early investor in sports and health startups.",
   "Manuel Luque":"Manuel Luque",
   "Nuestro vicepresidente de ingeniería es el jefe de operaciones de la empresa. Durante los últimos 30 años, ha dirigido equipos de programadores de primer nivel. Tiene experiencia en ingeniería de sistemas y una sólida formación técnica como DBA, arquitecto de la nube de AWS, backend y programador móvil.":"Our VP of Engineering is the company's chief operating officer. For the past 30 years, he had led high-seniority developer teams. He has a formation in system engineering and a strong technical background as a DBA, AWS Cloud Architect, Backend and Mobile Developer.",
   "Nuestras tecnologías":"Our technologies",
   "Nuestros números":"Our numbers",
   "10 Tecnologías esenciales":"10 Core technologies",
   "Expertos en los lenguages más utilizados.":"Experts in the most adopted languages.",
   "+13 Años de experiencia":"+13 Years of experience",
   "Equipo multidisciplinario y multicultural.":"Multidisciplinary and multicultural team.",
   "3 Industrias principales":"3 Main industries",
   "Sportechs, Healthtechs & Insurtechs.":"Sportechs, Healthtechs & Insurtechs.",
   "300 Proyectos":"+300 Projects",
   "Garantizamos eficiencia y calidad en nuestras entregas.":"We grant efficiency and quality in our deliveries.",
   "¡Tomemos un café!":"Let's have a coffee!",
   "No debería tomarnos tanto tiempo programar una reunión de 15 minutos":"It shouldn't take so many e-mails to schedule a 15 minute meeting",
   "No debería tomarnos tanto tiempo": "It shouldn't take so many e-mails",
    "programar una reunión de 15 minutos": "to schedule a 15 minute meeting",
   "Nombre":"Name",
   "Dirección de e-mail":"E-mail address",
   "Asunto":"Subject",
   "Mensaje":"Message",
   "Enviar":"Send",
   "Tú Nombre": "Your Name",
    "Tú Email": "Your Email",
    "Interesado en": "Interested in",
    "Requerido": "Required",
    "© Copyright": "© Copyright",
    "Servicios tecnológicos - Todos los derechos reservados.":"Technological services - All rights reserved",
    "¡Pagina web en construccion!": "We are currently building this website!",
}

module.exports = {
    eng_json
}